import React, { useContext, useEffect } from 'react'
import { Row, Col, Container } from '@kogk/common'
import { MainLayout, ProductRow, ButtonLink, CartSummary } from '@cmp/site'
import { GlobalDataProvider } from '@src/data/global-data'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from '@fortawesome/pro-solid-svg-icons'
import styles from './CartOverview.module.scss'
import { CartVisibility } from '@src/context/CartVisibility'
import { MenuTypeContext } from '@src/context/MenuTypeContext'
import { useCartState } from '@src/context/Cart'

export default ({ cart }) => {
  const { language } = useLanguage()
  const { modifyCartVisibility } = useContext(CartVisibility)
  const { modify } = useContext(MenuTypeContext)
  const { cartSize } = useCartState()
  const heading = !!cartSize ? 'Karfan mín' : 'Karfan er tóm'

  useEffect(() => {
    modifyCartVisibility(false)
    modify('primary', 'Karfan þín')
  }, [])

  return (
    <GlobalDataProvider language={language} altUrls={[]}>
      <MainLayout>
        <Container className={styles.container}>
          <Row className='pt-3 pb-1'>
            <Col offset={{ lg: 1, xl: 2 }}>
              <h3 className='blue'>
                <FontAwesomeIcon icon={faShoppingCart} className='mr-2' />
                {heading}
              </h3>
            </Col>
          </Row>

          <Row className='position-relative'>
            <Col
              col={{ lg: 5 }}
              offset={{ lg: 1, xl: 2 }}
              className={styles.productContainer}
            >
              {cart.products.map(item => {
                const productVariant =
                  item.product.title !== item.productGroup.title
                    ? item.product.title
                    : null
                return (
                  <ProductRow
                    key={item.id}
                    image={
                      (
                        item.product.images[0] &&
                        ( 
                          item.product.images[0].originalUrl ??
                          item.product.images[0].largeUrl
                        )
                      )
                      ??
                      (
                        item.productGroup.images[0] &&
                        (
                          item.productGroup.images[0].originalUrl ??
                          item.productGroup.images[0].largeUrl ??
                          item.productGroup.images[0].thumbnailUrl 
                        )
                      )
                    }
                    description={item.productGroup.title}
                    price={item.totalPrice}
                    quantity={item.quantity}
                    maxQuantity={item.product.maxQuantity}
                    variants={[productVariant]}
                    productType='product'
                    id={item.id}
                    productLink={`/${item.productGroup.category.slug}/${item.productGroup.id}`}
                    discount={item.discountPercent}
                    fullPrice={item.totalDiscount + item.totalPrice}
                  />
                )
              })}
              {cart.prescriptions.map(item => (
                <ProductRow
                  key={item.id}
                  description={item.prescription.description}
                  price={item.totalPrice}
                  quantity={item.quantity}
                  maxQuantity={item.prescription.maxQuantity}
                  variants={[]}
                  productType='prescription'
                  id={item.id}
                  discount={item.discountPercent}
                  fullPrice={item.totalDiscount + item.totalPrice}
                />
              ))}
            </Col>
            <Col col={{ lg: 4 }} className={styles.rightContainer}>
              <CartSummary price={cart.totalPrice} />

              <ButtonLink
                to='/checkout'
                className='w-100 py-2'
                label='Klára kaup'
                disabled={!cartSize}
              />
            </Col>
          </Row>
        </Container>
      </MainLayout>
    </GlobalDataProvider>
  )
}
